@value colors: '../../../../styles/colors.module.css';
@value gray-400, gray-100, dark-blue-200 from colors;

.root {
	display: flex;
	flex-direction: column;
}

.disabled > div {
	pointer-events: none;
	color: gray-400;
	background-color: gray-100;
	border-color: gray-100;
}

.disabled:hover {
	cursor: not-allowed;
}

.empty-choice {
	margin-bottom: 16px;
	border-radius: 4px;
	border: solid 2px dark-blue-200;
	display: grid;
	grid-template:
		'icon text' auto
		'. description' auto
		/ 24px 1fr;
	grid-column-gap: 12px;
	padding: 12px;
}

.empty-choice-title {
	font-size: 16px;
	grid-area: text;
	align-self: center;
}
