@value colors: '../../../../styles/colors.module.css';
@value dark-blue-400, dark-blue-200, dark-blue, dark-blue-050, white from colors;

.root {
	background-color: white;
	display: flex;
	border: solid 2px dark-blue-200;
	flex: 1 1;
	min-height: 48px;
	border-radius: 4px;
	margin: 0 8px;
	color: dark-blue;
	cursor: pointer;
}
.root:hover {
	background-color: dark-blue-050;
}
.root:focus {
	background-color: dark-blue-200;
}
.rootSelected {
	composes: root;
	border: solid 2px dark-blue;
	background-color: dark-blue;
	color: white;
}
.title {
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	padding: 14px;
	margin: 0 auto;
	text-align: center;
	align-self: center;
}
