@value colors: '../../styles/colors.module.css';
@value gray-850 from colors;

.changes {
	font-size: 14px;
	font-weight: normal;
	margin: 0 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #222222;
}

.status-text {
	margin: 0;
}

.saved-text {
	margin: 0;
	font-size: 12px;
}

.saving {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #222222;
}

.progress {
	margin-right: 16px;
}
