.main-text {
	font-family: 'Barlow', 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 1.1rem;
	color: rgba(0, 0, 0, 0.54);
}

.button-text {
	font-family: 'Barlow', 'Roboto', sans-serif;
	font-weight: 500;
	font-size: 16px;
	color: #2a3448;
}

.paper {
	min-width: 354px !important;
}
