@value colors: '../../../styles/colors.module.css';
@value dark-blue-200, dark-blue-400, dark-blue, error-600, gray-400,gray-100 from colors;

label.root,
.root label.error {
	color: dark-blue-400;
}

label.shrink {
	color: dark-blue-400;
}

label.focused,
.root:hover .shrink,
.root input {
	color: dark-blue;
}

.root.root fieldset {
	border-color: dark-blue-200;
}

.root input::placeholder {
	opacity: 1;
	color: dark-blue-200;
}

label.error.shrink,
p.error {
	color: error-600;
}

.root .textfield-error.textfield-error fieldset {
	border-color: error-600;
}

.root input:disabled {
	color: gray-400;
	background-color: gray-100;
	border-color: gray-100;
}

.root input:disabled::placeholder {
	color: darkgray;
}

.root input:disabled:hover {
	cursor: not-allowed;
}

.helper-root {
	margin-left: 0;
}
