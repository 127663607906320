@value colors: '../styles/colors.module.css';
@value blue-700 from colors;

.container {
	height: calc(100% - 64px);
}

.containerDesktop {
	display: grid;
	grid-template-columns: 296px 1fr;
}

.containerFullHeight {
	height: 100%;
}

.content {
	height: 100%;
	overflow: auto;
}
.sidebar {
	height: 100%;
	background-color: white;
}

.loader {
	position: fixed;
	z-index: 10;
	color: blue-700;
	background: white;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
