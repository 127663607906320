@value colors: '../../styles/colors.module.css';
@value gray-150, gray-400 from colors;

.root {
}

.top-side {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	height: 60px;
	border-bottom: 1px solid gray-400;
	z-index: 10;
	padding: 0 24px 0 24px;
	background-color: gray-150;
}
