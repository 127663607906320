@value colors: '../../../styles/colors.module.css';
@value blue-050, dark-blue-400, gray-200 from colors;

.container {
	grid-area: note;
	border-radius: 4px;
	border: solid 1px dark-blue-400;
	background-color: white;
	padding: 4px 16px 16px;
}

.container:hover {
	background-color: blue-050;
}

.top-section {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.note-title {
	font-size: 18px;
	font-weight: 600;
	color: #2a3448;
}

.note-content {
	font-size: 16px;
	color: #2a3448;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 9;
	-webkit-box-orient: vertical;
}

.overflow-button {
	padding: 0 !important;
}

.overflow-button-container {
	display: flex;
	align-items: center;
	justify-content: center;
}
