@value colors: '../../styles/colors.module.css';
@value dark-blue, blue-050, white, gray-200 from colors;

.root {
	display: grid;
	grid-template:
		'number title actions' auto
		'_ answers answers' auto
		'_ note note' auto
		/ 40px 1fr auto;
	padding: 24px;
	border-bottom: gray-200;
	grid-row-gap: 22px;
	justify-content: center;
	align-items: center;
}
.number {
	grid-area: number;
	width: 24px;
	height: 24px;
	display: inline-block;
	background-color: dark-blue;
	color: white;
	text-align: center;
	border-radius: 12px;
	line-height: 24px;
	font-weight: 600;
	font-size: 14px;
}
.description {
	margin-top: 8px;
}

.icon-wrapper {
	height: 100%;
}
.title {
	grid-area: title;
	font-weight: 600;
	font-size: 16px;
	color: #222222;
}
.actions {
	display: flex;
	align-items: center;
	justify-content: center;
	grid-area: actions;
}
.answer {
	grid-area: answers;
}

.action-icon {
	margin: 0 12px;
}

.action-icon:hover {
	cursor: pointer;
}

.add-note-button {
	font-weight: 600;
	font-size: 13px;
}

.add-comment-icon {
	margin-right: 8px;
}
