@value colors: '../../../../styles/colors.module.css';
@value dark-blue-400, dark-blue-200, dark-blue from colors;

.root {
	background-color: white;
	margin-bottom: 16px;
	border-radius: 4px;
	border: solid 2px dark-blue-200;
	display: grid;
	grid-template:
		'icon text' auto
		'. description' auto
		/ 24px 1fr;
	grid-column-gap: 12px;
	padding: 12px;
	cursor: pointer;
}
.root:hover {
	border: solid 2px dark-blue-400;
}
.rootSelected,
.root:focus {
	border: solid 2px dark-blue;
}
.rootSelected {
	composes: root;
}
.title {
	font-size: 16px;
	font-weight: 600;
	grid-area: text;
	align-self: center;
}
.icon {
	font-size: 20px;
	grid-area: icon;
}
.description {
	grid-area: description;
	margin-top: 4px;
}
