@value colors: '../../../../styles/colors.module.css';
@value gray-400, gray-100 from colors;

.root {
	display: flex;
	margin: 0 -8px;
}

.disabled > div {
	pointer-events: none;
	color: gray-400;
	background-color: gray-100;
	border-color: gray-100;
}

.disabled:hover {
	cursor: not-allowed !important;
}
