@value colors: './colors.module.css';
@value dark-blue from colors;
@value font: 'Barlow', 'Roboto', sans-serif;

body,
html,
#root {
	font-family: font;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	color: dark-blue;
}

.h1 {
	font-size: 28px;
	font-weight: 600;
	line-height: 40px;
}

.h2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
}

.h3 {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.h4 {
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
}

.h5 {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
}

.body-medium {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.body-medium-bold {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.body-small {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.body-small-bold {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
}

.caption {
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}

.overline {
	font-size: 10px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.button-small {
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
}

.button-medium {
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
}

.button-large {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
}
