@value colors: '../../../styles/colors.module.css';
@value typography: '../../../styles/typography.module.css';
@value gray-400, dark-blue, blue-050, blue-700, white from colors;
@value h4 from typography;

.card {
	min-height: 100%;
}

.card-data {
	position: relative;
	cursor: pointer;
	padding: 24px 16px;
	text-align: left;
	margin-top: -3px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.card-title {
	padding-right: 24px;
}

.card-data > div {
	width: 100%;
}

.card-header {
	composes: h4;
	font-family: Barlow;
	color: blue-700;
	width: 75%;
	margin: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 2px;
	white-space: nowrap;
}

.card-subheader {
	display: block;
	font-family: Barlow;
	width: 75%;
	margin: 0 0 8px;
	font-size: 18px;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 16px;
	white-space: nowrap;
}

.card-footer {
	width: 75%;
	height: 20px;
	margin-top: 12px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: gray-400;
	font-family: Barlow;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.card .card-more {
	top: 12px;
	right: 8px;
	position: absolute;
	border-radius: 100%;
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: unset;
}

.card-more:hover {
	background-color: blue-050;
	cursor: pointer;
}

.card-more:focus {
	background-color: blue-050;
}
